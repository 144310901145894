<template>
    <div>
        <div class="right-menu shipping-right">

            <subHeader pageName="ACCOUNTS"/>

            <tutorialsAction />

            <div class="db_top">
                <div class="db-container">

                    <moduleStats/>

                    <!-- search block starts here -->
                    <div class="formOptionsBox">
                        <div class="searchOptionsWrap">
                            <div class="searchBlock" id="accounts-search">
                                <form @submit.prevent="">
                                    <div class="searchIconWrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.491 17.49">
                                            <path id="Path_10" data-name="Path 10" d="M16,17.49h0L11,12.5v-.79l-.27-.28a6.512,6.512,0,1,1,.7-.7l.28.27h.79l4.99,5L16,17.489ZM6.5,2A4.5,4.5,0,1,0,11,6.5,4.505,4.505,0,0,0,6.5,2Z" fill="#848484"/>
                                        </svg>
                                    </div>
                                    <input
                                        type="text"
                                        name="search"
                                        id="search"
                                        ref="searchInput"
                                        placeholder="Search"
                                        v-model="searchedItem"
                                        @input="searchAccount()"
                                        :disabled="tourIsActive"
                                    />
                                </form>
                            </div>
                            <div class="filterShowTabsRow" v-if="searchedItem.length > 0">
                                <div class="filterShowTabsListSearchedQuery">
                                    <p>Search results for "{{ searchedItem.length > 20 ? searchedItem.substr(0, 20) + "..." : searchedItem }}".</p>
                                </div>
                                <div class="filterShowTabsResetBtn">
                                    <button @click.prevent="clearSearch">Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- search block ends here -->

                    <!-- data table starts here -->
                    <div class="tableMainWrapBox" style="margin-top: 25px;position: relative;" id="preview-record">
                        <div class="tableScrollWrap" ref="accountsTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <input 
                                                        type="checkbox"
                                                        @click="toggleIsCheckedAll()"
                                                        :checked="checkedItems.length != 0 && checkedItems.length == accountsList.length"
                                                        :disabled="tourIsActive || this.previewForm.id != ''"
                                                    >
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Name">Name</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Code">Code</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Number">Number</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Bank Name">Bank</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Currency">Currency</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="accountsLoading">
                                    <tr v-for="i in 5" :key="i">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="100"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-if="accountsList && accountsList.length == 0">
                                        <td colspan="7" style="text-align:center"><p class="mb-0">No Accounts Available</p></td>
                                    </tr>
                                    <tr v-for="(listedAccount, index) in accountsList" :key="index">

                                        <!-- checkbox block starts here -->
                                        <td @click="previewAccount(listedAccount,index,'input',$event)">
                                            <div class="ctmCheckWrap" data-open="true">
                                                <label 
                                                    class="ctmTableCheckContainer" 
                                                    data-open="false" 
                                                    @click.prevent=" listedAccount.id != -1 ? checkedItemHandle(listedAccount.id) : '' "
                                                >
                                                    <input 
                                                        type="checkbox" 
                                                        v-model="checkedItems" 
                                                        :value="listedAccount.id" 
                                                        :disabled="tourIsActive || listedAccount.id == -1" 
                                                        data-open="false"
                                                    >
                                                    <span class="checkmark" data-open="false"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <!-- checkbox block starts here -->

                                        <!-- account name block starts here -->
                                        <td class="mainTableTitle" @click="previewAccount(listedAccount,index,'input',$event)">
                                            <div class="editFieldTitle" data-open="true" id="account-name-field-3">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write an account name"
                                                    v-model="listedAccount.account_name" 
                                                    @focus="accountSelection(listedAccount)" 
                                                    @blur="handleOTGUpdate(listedAccount,index)" 
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- account name block ends here -->

                                        <!-- account code block starts here -->
                                        <td class="mainTableTitle" @click="previewAccount(listedAccount,index,'input',$event)">
                                            <div class="editFieldTitle" data-open="true" id="account-code-field-2">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write an account code"
                                                    v-model="listedAccount.account_code" 
                                                    @focus="accountSelection(listedAccount)" 
                                                    @blur="handleOTGUpdate(listedAccount,index)" 
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- account code block ends here -->

                                        <!-- account number block starts here -->
                                        <td class="mainTableTitle" @click="previewAccount(listedAccount,index,'input',$event)">
                                            <div class="editFieldTitle" data-open="true" id="account-no-field-2">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write an account number"
                                                    v-model="listedAccount.account_no" 
                                                    @focus="accountSelection(listedAccount)" 
                                                    @blur="handleOTGUpdate(listedAccount,index)" 
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- account number block ends here -->

                                        <!-- bank name block starts here -->
                                        <td class="mainTableTitle" @click="previewAccount(listedAccount,index,'input',$event)">
                                            <div class="editFieldTitle" data-open="true" id="account-bank-name-field-2">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a bank name"
                                                    v-model="listedAccount.bank_name" 
                                                    @focus="accountSelection(listedAccount)" 
                                                    @blur="handleOTGUpdate(listedAccount,index)" 
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- bank name block ends here -->

                                        <!-- currency block starts here -->
                                        <td style="position: relative;" @click.prevent="previewAccount(listedAccount,index,'solid',$event)">
                                            <div class="selectCategoryBoxTable" data-open="true">
                                                <p data-open="true">
                                                    {{ listedAccount.currency ? listedAccount.currency.length > 10 ? 
                                                    listedAccount.currency.substr(0,10) + "..." : listedAccount.currency : '--' }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- currency block ends here -->

                                        <!-- action block starts here -->
                                        <td @click.prevent="previewAccount(listedAccount,index,'input',$event)">
                                            <div class="ctmDataTableActionBtn" v-if="isDeletingItem(listedAccount.id) && deleteLoader" data-open="false">
                                                <loaderBtn />
                                            </div>
                                            <div class="ctmDataTableActionBtn" data-open="true" v-else>
                                                <button class="delFunction" @click.prevent="deleteAccountHandle(listedAccount,index)" :disabled="tourIsActive" data-open="false">
                                                    <i aria-hidden="true" class="fa fa-trash-o" data-open="false"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <!-- action block ends here -->

                                    </tr>

                                    <!-- load more block starts here -->
                                    <tr v-if="isPending">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="100"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                    </tr>
                                    <!-- load more block ends here -->

                                </tbody>
                            </table>
                        </div>

                        <!-- add/update account form starts here -->
                        <div class="productSlideBox" ref="viewInputForm" :class="previewForm.id ? 'activeRow': ''" >
                            <div class="tableActionBtns" id="account-details-preview">
                                <div class="productTableSideBar">
                                    <div class="row">
                                        <div class="col-md-8" id="account-name-field-2">
                                            <div class="viewOrderTitle">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write an account name" 
                                                    v-model="previewForm.account_name"
                                                    :disabled="tourIsActive"
                                                >
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="orderViewNav">
                                                <ul>
                                                    <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                                                        <li class="sm-extand-hide">
                                                            <button @click.prevent="expand" :disabled="tourIsActive" id="expand-form">
                                                                <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                                                                    <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                                                        <path d="m16.5 5.5v-4.978l-5.5.014"/>
                                                                        <path d="m16.5.522-6 5.907"/>
                                                                        <path d="m11 16.521 5.5.002-.013-5.5"/>
                                                                        <path d="m16.5 16.429-6-5.907"/>
                                                                        <path d="m.5 5.5v-5h5.5"/>
                                                                        <path d="m6.5 6.429-6-5.907"/>
                                                                        <path d="m6 16.516-5.5.007v-5.023"/>
                                                                        <path d="m6.5 10.5-6 6"/>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                    <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                                                        <li>
                                                            <button @click.prevent="closePreview" id="closeSide" :disabled="tourIsActive">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                                                                    <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                                                        <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                        <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                                                            <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                            <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sectionTabsLink">
                                    <ul onclick="window.myFunction(event)">
                                        <li v-if="!tourIsActive">
                                            <a class="active" id="category-detail-navbtn" href="#accountDetails">Account Info</a>
                                        </li>
                                    </ul>
                                    <div class="updateBtnBox" v-if="accountIsSaving">
                                        <loaderBtn/>
                                    </div>
                                    <div class="updateBtnBox" id="save-account-btn" v-else>
                                        <button 
                                            form="account-information" 
                                            type="submit"
                                            :disabled="tourIsActive"
                                        >
                                            {{ previewForm.id == -1 ? 'Save' : 'Update' }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <ValidationObserver ref="accountFormObserver">
                                <div class="productTableInfoBlock" id="accountDetails">
                                    <div class="productTableInfoWhiteBox">
                                        <form id="account-information" @submit.prevent="validate()">
                                            <div class="tableFromFieldBox">
                                                <label for="account-name-field-1">Account Name<span>*</span></label>
                                                <ValidationProvider name="account name" rules="required|max:250" v-slot="{ errors }">
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            name="account-name-field-1" 
                                                            id="account-name-field-1" 
                                                            placeholder="Account name" 
                                                            v-model="previewForm.account_name"
                                                            :disabled="tourIsActive" 
                                                        >
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="account-code-field">Account Code<span>*</span></label>
                                                <ValidationProvider name="account code" :rules="{required:true,regex:'^[0-9][0-9]*$',max:250}" v-slot="{ errors }">
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            name="account-code-field" 
                                                            id="account-code-field" 
                                                            placeholder="Account code" 
                                                            v-model="previewForm.account_code"
                                                            :disabled="tourIsActive"
                                                        >
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="account-no-field">Account Number<span>*</span></label>
                                                <ValidationProvider name="account number" :rules="{required:true,regex:'^[0-9][0-9]*$',max:250}" v-slot="{ errors }">
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            name="account-no-field" 
                                                            id="account-no-field" 
                                                            placeholder="Account number" 
                                                            v-model="previewForm.account_no"
                                                            :disabled="tourIsActive" 
                                                        >
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="bank-name-field">Bank Name<span>*</span></label>
                                                <ValidationProvider name="bank name" rules="required|max:250" v-slot="{ errors }">
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            name="bank-name-field" 
                                                            id="bank-name-field" 
                                                            placeholder="Bank name" 
                                                            v-model="previewForm.bank_name"
                                                            :disabled="tourIsActive"
                                                        >
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="account-currency-field">Currency<span>*</span></label>
                                                <ValidationProvider name="currency" rules="required" v-slot="{ errors }">
                                                    <div class="vueCtmSelectBlock">
                                                        <select 
                                                            name="account-currency-field" 
                                                            id="account-currency-field" 
                                                            v-model="previewForm.currency"
                                                            :disabled="tourIsActive"
                                                        >
                                                            <option :value="currency" v-for="(currency,index) in currencyList" :key="index">{{ currency }}</option>
                                                        </select>
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <ValidationProvider name="description" rules="required|max:500" v-slot="{ errors }">
                                                    <label for="account-description-field">Description<span>*</span></label>
                                                    <div class="tableFromFieldItem">
                                                        <textarea 
                                                            name="account-description-field" 
                                                            id="account-description-field" 
                                                            placeholder="Description" 
                                                            v-model="previewForm.description"
                                                            :disabled="tourIsActive"
                                                        >
                                                        </textarea>
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                        <!-- add/update account form starts here -->

                    </div>
                    <!-- data table ends here -->

                    <!-- bottom action bar starts here -->
                    <div class="row product-bottom-bar m-0" v-if="checkedItems.length > 1">
                        <div class="col-md-6 model-footer-main1">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != accountsList.length">
                                    <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                    <p>SELECT ALL</p>
                                </a>
                                <a href="" class="model-footer-1" @click.prevent="clearSelection()">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                        <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                    </svg>
                                    <p>CLEAR SELECTION</p>
                                </a>
                            </div>
                            <div class="model-footer-2">{{ checkedItems.length }}</div>
                        </div>
                        <div class="col-md-6 model-footer-main1 product-row-reverse">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 ml-4" @click.prevent="accountBulkActionHandle('delete')">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    <p>DELETE</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- bottom action bar ends here -->

                </div>
            </div>

        </div>

        <!-- vue tour -->
        <v-tour name="accountsTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>

<script>
import subHeader from "@/components/subHeader.vue";
import moduleStats from "@/components/moduleStats.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import _ from 'lodash';
import { mapGetters } from 'vuex';
import {MessageBox, Message, Loading} from 'element-ui'
import loaderBtn from "@/components/loaderBtn.vue";

export default {
    metaInfo(){
        return{
            title: 'Expenses/Purchases | Accounts | YeetCommerce',
        };
    },
    data(){
        return {
            //search start
            searchedItem:'',
            //search end

            //accounts start
            accountsList:[],
            accountsLoading:false,
            accountIsSaving:false,
            accountSaved:false,
            previewForm:{
                id:'',
                account_name:'',
                account_code:'',
                currency:'',
                account_no:'',
                bank_name:'',
                description:'',
                isTouched:false,
            },
            defaultPreviewForm:{
                id:'',
                account_name:'',
                account_code:'',
                currency:'',
                account_no:'',
                bank_name:'',
                description:'',
                isTouched:false,
            },
            tempAccount:{
                id:'',
                account_name:'',
                account_code:'',
                currency:'',
                account_no:'',
                bank_name:'',
                description:'',
                isTouched:false,
            },
            tempPreviewAccount:{
                id:'',
                account_name:'',
                account_code:'',
                currency:'',
                account_no:'',
                bank_name:'',
                description:'',
                isTouched:false,
            },
            currencyList:[],
            //accounts end

            //checked items start
            checkedItems:[],
            deletingItems:[],
            deleteLoader:false,
            timer: undefined,
            isMasterSearched:false,
            //checked items end

            //tour start
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //accounts steps
                {
                    target: '#preview-record',
                    content: `<strong>Accounts Table</strong><br>You can preview and edit the added accounts by clicking on each individual record present in the table.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'top'
                    }
                },
                {
                    target: '#accounts-search',
                    content: `<strong>Search Account</strong><br>It enables you to search specific accounts.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#add-new-record',
                    content: `Add a <strong>Account</strong><br>By clicking this button, you can add a new account and open a form to input and save the details.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#account-details-preview',
                    content: `<strong>Account Details Form</strong><br>This form can be used to add details about the account and save them.`,
                    params: {
                        highlight: false,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#account-name-field-1',
                    content: `<strong>Account Name Field</strong><br>You can add the name of account using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#account-code-field',
                    content: `<strong>Account Code Field</strong><br>You can add the account code using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#account-no-field',
                    content: `<strong>Account Number Field</strong><br>You can add the account number using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#bank-name-field',
                    content: `<strong>Bank Name Field</strong><br>You can add the bank name using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#account-currency-field',
                    content: `<strong>Currency Field</strong><br>You can select the currency of the account using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#account-description-field',
                    content: `<strong>Account Description Field</strong><br>You can add the account description using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#account-name-field-3',
                    content: `<strong>Account Name Field</strong><br>You can also add the name of account using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#account-code-field-2',
                    content: `<strong>Account Code Field</strong><br>You can also add the account code using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#account-no-field-2',
                    content: `<strong>Account Number Field</strong><br>You can also add the account number using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#account-name-field-2',
                    content: `<strong>Account Name Field</strong><br>You can also add the name of account using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#expand-form',
                    content: `<strong>Expand Form</strong><br>Form can be expanded or collapsed by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#closeSide',
                    content: `<strong>Close Form</strong><br>Form can be closed using this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#save-account-btn',
                    content: `<strong>Save Account Details</strong><br>Added account details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
            ],
            tourIsActive:false,
            //tour end
        }
    },
    components:{
        subHeader,
        tutorialsAction,
        moduleStats,
        loaderBtn
    },
    computed:{
        ...mapGetters({
            settings:'settings_module/settings',
            storeAccounts:'accounts_module/accounts',
            accountsCurrentPage: 'accounts_module/currentPage',
            accountsLastPage: 'accounts_module/lastPage',
            accountsSearchKeyword:'accounts_module/keyword',
            isPending:'accounts_module/isPending',
            storeAllCountries: 'countries_module/allCountries'
        }),
    },
    watch:{
        'storeAccounts':{
            handler:function(value){

                this.accountsLoading = false;

                if(this.previewForm.id == -1){
        
                    this.accountsList = _.cloneDeep(value);
                    
                    this.accountsList.unshift(this.previewForm);

                }else if(this.tempPreviewAccount.id != ''){

                    this.accountsList = _.cloneDeep(value);

                    this.accountsList[this.previewForm.index] = this.previewForm;

                }else{

                    this.accountsList = _.cloneDeep(value);

                }

                if(this.isMasterSearched){

                    this.previewSearchedAccount(this.accountsList[0],0);

                    this.isMasterSearched = false;

                }

            },deep:true
        },
        'storeAllCountries':{
            handler:function(value){

                let tempCurrencies = [];

                value.forEach( country => {
                    
                    tempCurrencies.push(country.currency_unit);

                })

                this.currencyList = _.cloneDeep(tempCurrencies);

            },deep:true
        }
    },
    methods:{

        //infinite scroll start
        handleScrollEvent(event){

            let element = this.$refs.accountsTable;

            let maxScrollTop = element.scrollHeight - element.clientHeight;

            if(element.scrollTop >= maxScrollTop - 100){

                if(this.isPending == false && this.accountsCurrentPage != this.accountsLastPage){

                    this.$store.dispatch('accounts_module/fetchStoreAccountsRequest',{size:11, page: this.accountsCurrentPage + 1, loadData: 'more'});
                
                }
            
            }

        },
        //infinite scroll end

        //OTG update start
        accountSelection(listedAccount){

            this.tempAccount = _.cloneDeep(listedAccount);

        },
        async handleOTGUpdate(listedAccount,index){

            if(this.previewForm.id == ''){

                if(listedAccount.id != -1){
                    
                    let isChanged = false

                    if((this.tempAccount.account_name != listedAccount.account_name) || (this.tempAccount.account_code != listedAccount.account_code) ||
                    (this.tempAccount.account_no != listedAccount.account_no) || (this.tempAccount.bank_name != listedAccount.bank_name)){

                        isChanged = true

                    }

                    if(isChanged){

                        let isRequired = false;

                        if(listedAccount.account_name.length == 0){
            
                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The account name field is required.",
                            })

                            isRequired = true

                        }

                        if(listedAccount.account_name.length > 250){
            
                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The account name field may not be greater than 250 characters.",
                            })

                            isRequired = true

                        }

                        if(listedAccount.account_code.length == 0){
            
                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The account code field is required.",
                            })

                            isRequired = true

                        }

                        if(listedAccount.account_code.length > 250){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The account code field may not be greater than 250 characters.",
                            })

                            isRequired = true

                        }

                        let regex = new RegExp('^[0-9][0-9]*$');

                        let isValidAccountCode = regex.test(listedAccount.account_code)

                        if(listedAccount.account_code && !isValidAccountCode){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The account code may only contain numbers.",
                            })

                            isRequired = true;

                        }

                        if(listedAccount.account_no.length == 0){
            
                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The account no. field is required.",
                            })

                            isRequired = true

                        }

                        if(listedAccount.account_no.length > 250){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The account no. field may not be greater than 250 characters.",
                            })

                            isRequired = true

                        }
                        
                        let isValidAccountNo = regex.test(listedAccount.account_no)

                        if(listedAccount.account_no && !isValidAccountNo){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The account no. may only contain numbers.",
                            })

                            isRequired = true;

                        }

                        if(listedAccount.bank_name.length == 0){
            
                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The bank name field is required.",
                            })

                            isRequired = true

                        }

                        if(listedAccount.bank_name.length > 250){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The bank name field may not be greater than 250 characters.",
                            })

                            isRequired = true

                        }

                        if(isRequired){

                            this.accountsList[index].account_name = this.tempAccount.account_name;
                            this.accountsList[index].account_code = this.tempAccount.account_code;
                            this.accountsList[index].account_no = this.tempAccount.account_no;
                            this.accountsList[index].bank_name = this.tempAccount.bank_name;

                            return

                        }

                        MessageBox.confirm(
                            'You have unsaved changes, save and proceed?',
                            'Confirm',
                            {
                                distinguishCancelAndClose: true,
                                confirmButtonText: 'Save',
                                cancelButtonText: 'Discard Changes',
                            }
                        ).then(async () => {

                            let formData = new FormData();

                            formData.append("id",listedAccount.id);
                            formData.append("account_name",listedAccount.account_name.trim());
                            formData.append("account_code",listedAccount.account_code);
                            formData.append("currency",listedAccount.currency);
                            formData.append("account_no",listedAccount.account_no);
                            formData.append("bank_name",listedAccount.bank_name);
                            formData.append("description",listedAccount.description);

                            let loader = Loading.service({
                                text: "The account is being updated. Please wait!",
                                fullscreen: true,
                            });

                            try{
                                let res = await this.$axios.post('account/update',formData);
                                if(res.data.status_code == "1054"){

                                    this.$notify({
                                        type: 'success',
                                        title: 'Success',
                                        message: res.data.message,
                                    });

                                    this.$store.commit('expenses_module/CLEAR_MODULE');

                                    this.$store.commit('accounts_module/update_accounts_list',res.data.account);

                                }
                            }catch(error){

                                this.accountsList[index].account_name = this.tempAccount.account_name;
                                this.accountsList[index].account_code = this.tempAccount.account_code;
                                this.accountsList[index].account_no = this.tempAccount.account_no;
                                this.accountsList[index].bank_name = this.tempAccount.bank_name;

                                if(error.response){

                                    if(error.response.data.error.id){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error.id[0],
                                        });

                                    }else if(error.response.data.error.includes('already exists')){
                                        
                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            dangerouslyUseHTMLString: true,
                                            message: error.response.data.error,
                                        });

                                    }else if(error.response.data.error.includes("doesn't support")){

                                        let subscriptionPath = window.location.origin + "/subscription";

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            dangerouslyUseHTMLString: true,
                                            message: error.response.data.error 
                                            + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                        });

                                    }else{

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.message,
                                        });

                                    }

                                }else{

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        message: error.message,
                                    });

                                }

                                this.tempAccount = null;

                            }finally{

                                loader.close();

                            }

                        }).catch(() => {

                            this.accountsList[index].account_name = this.tempAccount.account_name;
                            this.accountsList[index].account_code = this.tempAccount.account_code;
                            this.accountsList[index].account_no = this.tempAccount.account_no;
                            this.accountsList[index].bank_name = this.tempAccount.bank_name;

                            this.$notify({
                                type: "info",
                                title: "Changes Discarded",
                                message: "Changes discarded successfully.",
                            });

                            this.tempAccount = null;

                        })

                    }

                }

            }else{

                if(listedAccount.id != -1){

                    if(listedAccount.id != this.previewForm.id){

                        let isChanged = false

                        if((this.tempAccount.account_name != listedAccount.account_name) || (this.tempAccount.account_code != listedAccount.account_code) ||
                        (this.tempAccount.account_no != listedAccount.account_no) || (this.tempAccount.bank_name != listedAccount.bank_name)){

                            isChanged = true

                        }

                        if(isChanged){

                            let isRequired = false;

                            if(listedAccount.account_name.length == 0){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The account name field is required.",
                                })

                                isRequired = true

                            }

                            if(listedAccount.account_name.length > 250){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The account name field may not be greater than 250 characters.",
                                })

                                isRequired = true

                            }

                            if(listedAccount.account_code.length == 0){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The account code field is required.",
                                })

                                isRequired = true

                            }

                            if(listedAccount.account_code.length > 250){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The account code field may not be greater than 250 characters.",
                                })

                                isRequired = true

                            }

                            let regex = new RegExp('^[0-9][0-9]*$');

                            let isValidAccountCode = regex.test(listedAccount.account_code)

                            if(listedAccount.account_code && !isValidAccountCode){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The account code may only contain numbers.",
                                })

                                isRequired = true;

                            }

                            if(listedAccount.account_no.length == 0){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The account no. field is required.",
                                })

                                isRequired = true

                            }

                            if(listedAccount.account_no.length > 250){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The account no. field may not be greater than 250 characters.",
                                })

                                isRequired = true

                            }
                            
                            let isValidAccountNo = regex.test(listedAccount.account_no)

                            if(listedAccount.account_no && !isValidAccountNo){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The account no. may only contain numbers.",
                                })

                                isRequired = true;

                            }

                            if(listedAccount.bank_name.length == 0){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The bank name field is required.",
                                })

                                isRequired = true

                            }

                            if(listedAccount.bank_name.length > 250){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The bank name field may not be greater than 250 characters.",
                                })

                                isRequired = true

                            }

                            if(isRequired){

                                this.accountsList[index].account_name = this.tempAccount.account_name;
                                this.accountsList[index].account_code = this.tempAccount.account_code;
                                this.accountsList[index].account_no = this.tempAccount.account_no;
                                this.accountsList[index].bank_name = this.tempAccount.bank_name;

                                return

                            }

                            MessageBox.confirm(
                                'You have unsaved changes, save and proceed?',
                                'Confirm',
                                {
                                    distinguishCancelAndClose: true,
                                    confirmButtonText: 'Save',
                                    cancelButtonText: 'Discard Changes',
                                }
                            ).then(async () => {

                                let formData = new FormData();

                                formData.append("id",listedAccount.id);
                                formData.append("account_name",listedAccount.account_name);
                                formData.append("account_code",listedAccount.account_code);
                                formData.append("currency",listedAccount.currency);
                                formData.append("account_no",listedAccount.account_no);
                                formData.append("bank_name",listedAccount.bank_name);
                                formData.append("description",listedAccount.description);

                                let loader = Loading.service({
                                    text: "The account is being updated. Please wait!",
                                    fullscreen: true,
                                });

                                try{
                                    let res = await this.$axios.post('account/update',formData);
                                    if(res.data.status_code == "1054"){

                                        this.$notify({
                                            type: 'success',
                                            title: 'Success',
                                            message: res.data.message,
                                        });

                                        this.$store.commit('expenses_module/CLEAR_MODULE');

                                        this.$store.commit('accounts_module/update_accounts_list',res.data.account);

                                    }
                                }catch(error){

                                    this.accountsList[index].account_name = this.tempAccount.account_name;
                                    this.accountsList[index].account_code = this.tempAccount.account_code;
                                    this.accountsList[index].account_no = this.tempAccount.account_no;
                                    this.accountsList[index].bank_name = this.tempAccount.bank_name;

                                    if(error.response){

                                        if(error.response.data.error.id){

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.error.id[0],
                                            });

                                        }else if(error.response.data.error.includes('already exists')){
                                            
                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                dangerouslyUseHTMLString: true,
                                                message: error.response.data.error,
                                            });

                                        }else if(error.response.data.error.includes("doesn't support")){

                                            let subscriptionPath = window.location.origin + "/subscription";

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                dangerouslyUseHTMLString: true,
                                                message: error.response.data.error 
                                                + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                            });

                                        }else{

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.message,
                                            });

                                        }

                                    }else{

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.message,
                                        });

                                    }

                                    this.tempAccount = null;

                                }finally{

                                    loader.close();

                                }

                            }).catch(() => {

                                this.accountsList[index].account_name = this.tempAccount.account_name;
                                this.accountsList[index].account_code = this.tempAccount.account_code;
                                this.accountsList[index].account_no = this.tempAccount.account_no;
                                this.accountsList[index].bank_name = this.tempAccount.bank_name;

                                this.$notify({
                                    type: "info",
                                    title: "Changes Discarded",
                                    message: "Changes discarded successfully.",
                                });

                                this.tempAccount = null;

                            })

                        }

                    }

                }

            }

        },
        //OTG update end

        //preview form open/close & reset start
        previewAccount(listedAccount,i,field,e){

            if(field == 'input'){

                if((e.target.tagName == "TD" || e.target.dataset.open == "true") && !this.tourIsActive){

                    if(listedAccount.id != -1){

                        if(this.previewForm.id == -1){

                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    type: "warning",
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                }
                            ).then(()=>{

                                let index = this.accountsList.findIndex((account) => account.id == -1);
                                
                                if(index != -1){
                    
                                    this.accountsList.splice(index, 1);
                    
                                }

                                if(!this.accountSaved){

                                    let tempIndex = this.accountsList.findIndex(x=>x.id == this.tempPreviewAccount.id);

                                    if(tempIndex != -1){

                                        this.accountsList[tempIndex] = this.tempPreviewAccount;

                                    }

                                }

                                let account = listedAccount;

                                this.previewForm = account;
                                this.previewForm.index = i;

                                this.checkedItems = [];

                                this.accountIsSaving = false;

                                this.tempPreviewCategory = _.cloneDeep(account);

                            }).catch(() => {})

                        }else{

                            let index = this.accountsList.findIndex((account) => account.id == -1);
                                
                            if(index != -1){
                
                                this.accountsList.splice(index, 1);
                
                            }

                            if(!this.accountSaved && (this.tempPreviewAccount.id != '' && this.tempPreviewAccount.id != listedAccount.id)){
                                
                                let tempIndex = this.accountsList.findIndex(x=>x.id == this.tempPreviewAccount.id);
                                
                                if(tempIndex != -1){

                                    this.accountsList[tempIndex] = this.tempPreviewAccount;

                                }

                            }

                            if(this.tempPreviewAccount.id != '' && this.tempPreviewAccount.id == listedAccount.id){

                                let tempIndex = this.accountsList.findIndex(x=>x.id == this.tempPreviewAccount.id);

                                this.accountsList[tempIndex] = _.cloneDeep(this.storeAccounts[tempIndex]);

                                let account = _.cloneDeep(this.accountsList[tempIndex]);

                                this.previewForm = this.accountsList[tempIndex];
                                this.previewForm.index = i;

                                this.checkedItems = [];

                                this.accountIsSaving = false;

                                this.tempPreviewAccount = _.cloneDeep(account);

                            }else{

                                let account = listedAccount;

                                this.previewForm = account;
                                this.previewForm.index = i;
                                
                                this.checkedItems = [];

                                this.accountIsSaving = false;

                                this.tempPreviewAccount = _.cloneDeep(account);

                            }

                        }

                    }

                }

            }else{

                if(!this.tourIsActive){
                
                    if(listedAccount.id != -1){

                        if(this.previewForm.id == -1){

                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    type: "warning",
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                }
                            ).then(()=>{

                                let index = this.accountsList.findIndex((account) => account.id == -1);
                                
                                if(index != -1){
                    
                                    this.accountsList.splice(index, 1);
                    
                                }

                                if(!this.accountSaved){

                                    let tempIndex = this.accountsList.findIndex(x=>x.id == this.tempPreviewAccount.id);

                                    if(tempIndex != -1){

                                        this.accountsList[tempIndex] = this.tempPreviewAccount;

                                    }

                                }

                                let account = listedAccount;

                                this.previewForm = account;
                                this.previewForm.index = i;

                                this.checkedItems = [];

                                this.accountIsSaving = false;

                                this.tempPreviewCategory = _.cloneDeep(account);

                            }).catch(() => {})

                        }else{

                            let index = this.accountsList.findIndex((account) => account.id == -1);
                                
                            if(index != -1){
                
                                this.accountsList.splice(index, 1);
                
                            }

                            if(!this.accountSaved && (this.tempPreviewAccount.id != '' && this.tempPreviewAccount.id != listedAccount.id)){
                                
                                let tempIndex = this.accountsList.findIndex(x=>x.id == this.tempPreviewAccount.id);
                                
                                if(tempIndex != -1){

                                    this.accountsList[tempIndex] = this.tempPreviewAccount;

                                }
                
                            }

                            if(this.tempPreviewAccount.id != '' && this.tempPreviewAccount.id == listedAccount.id){

                                let tempIndex = this.accountsList.findIndex(x=>x.id == this.tempPreviewAccount.id);

                                this.accountsList[tempIndex] = _.cloneDeep(this.storeAccounts[tempIndex]);

                                let account = _.cloneDeep(this.accountsList[tempIndex]);

                                this.previewForm = this.accountsList[tempIndex];
                                this.previewForm.index = i;

                                this.checkedItems = [];

                                this.accountIsSaving = false;

                                this.tempPreviewAccount = _.cloneDeep(account);

                            }else{

                                let account = listedAccount;

                                this.previewForm = account;
                                this.previewForm.index = i;
                                
                                this.checkedItems = [];

                                this.accountIsSaving = false;

                                this.tempPreviewAccount = _.cloneDeep(account);

                            }

                        }

                    }
                
                }

            }

        },
        previewSearchedAccount(listedAccount,i){

            let index = this.accountsList.findIndex((account) => account.id == -1);
                                
            if(index != -1){

                this.accountsList.splice(index, 1);

            }

            if(!this.accountSaved){

                let tempIndex = this.accountsList.findIndex(x=>x.id == this.tempPreviewAccount.id);

                if(tempIndex != -1){

                    this.accountsList[tempIndex] = this.tempPreviewAccount;

                }

            }

            let account = listedAccount;

            this.previewForm = account;
            this.previewForm.index = i;

            this.checkedItems = [];

            this.accountIsSaving = false;

            this.tempPreviewCategory = _.cloneDeep(account);

        },
        closePreview(){

            if(this.previewForm.isTouched){

                MessageBox.confirm(
                    "Do you want to close? Entered information will be discarded.", 
                    "Warning", 
                    {
                        type: "warning",
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                    }
                ).then(async () => {

                    let index = this.accountsList.findIndex((account) => account.id == -1);
                                
                    if(index != -1){
            
                        this.accountsList.splice(index, 1);
            
                    }
            
                    if(!this.accountSaved){
            
                        let tempIndex = this.accountsList.findIndex(x=>x.id == this.tempPreviewAccount.id);
            
                        if(tempIndex != -1){

                            this.accountsList[tempIndex] = this.tempPreviewAccount;

                        }

                    }

                    this.previewForm.id = '';

                    // this.previewForm = _.cloneDeep(this.defaultPreviewForm);
                    
                    this.accountSaved = false;
    
                    this.tempAccount = _.cloneDeep(this.defaultPreviewForm);
    
                    this.tempPreviewAccount = _.cloneDeep(this.defaultPreviewForm);

                }).catch(() => {})

            }else{

                let index = this.accountsList.findIndex((account) => account.id == -1);
                                
                if(index != -1){
        
                    this.accountsList.splice(index, 1);
        
                }
        
                if(!this.accountSaved){
        
                    let tempIndex = this.accountsList.findIndex(x=>x.id == this.tempPreviewAccount.id);
                    
                    if(tempIndex != -1){

                        this.accountsList[tempIndex] = this.tempPreviewAccount;

                    }
        
                }

                this.previewForm.id = '';

                // this.previewForm = _.cloneDeep(this.defaultPreviewForm);
                
                this.accountSaved = false;

                this.tempAccount = _.cloneDeep(this.defaultPreviewForm);

                this.tempPreviewAccount = _.cloneDeep(this.defaultPreviewForm);

            }

        },
        resetPreview(){

            let index = this.accountsList.findIndex((account) => account.id == -1);
            
            if(index != -1){
    
                this.accountsList.splice(index, 1);
    
            }
    
            if(!this.accountSaved){
    
                let tempIndex = this.accountsList.findIndex(x=>x.id == this.tempPreviewAccount.id);
                
                if(tempIndex != -1){

                    this.accountsList[tempIndex] = this.tempPreviewAccount;

                }
    
            }

            this.previewForm.id = '';

            // this.previewForm = _.cloneDeep(this.defaultPreviewForm);
            
            this.accountSaved = false;

            this.tempAccount = _.cloneDeep(this.defaultPreviewForm);

            this.tempPreviewAccount = _.cloneDeep(this.defaultPreviewForm);

        },
        createAccount(){

            if(this.accountsLoading){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "Accounts are being retrieved.",
                });

                return

            }

            if(this.accountIsSaving){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "An account is currently being processed.",
                });

                return

            }

            let index = this.accountsList.findIndex(x=>x.id == -1);

            if(index != -1){

                // this.$notify({
                //   type: "error",
                //   title: "Can't Add New Account",
                //   message: "you have an unsaved account in the list",
                // });

                return
            }

            this.closePreview();

            this.previewForm = _.cloneDeep(this.defaultPreviewForm);

            this.previewForm.id = -1;

            let account = this.previewForm;

            this.accountsList.unshift(account);

            this.checkedItems = [];

            this.$refs.accountFormObserver.reset();

        },
        //preview form open/close & reset end

        //add/update & delete account start
        validate(){

            this.$refs.accountFormObserver.validate().then(success => {

                if(!success){

                    const errors = Object.entries(this.$refs.accountFormObserver.errors)
                    .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

                    this.$refs.accountFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                }else{

                    if(this.previewForm.id == -1){

                        this.addAccountHandle();

                    }else{

                        this.updateAccountHandle();

                    }

                }

            });

        },
        async addAccountHandle(){

            let formData = new FormData();

            formData.append("account_name",this.previewForm.account_name.trim());
            formData.append("account_code",this.previewForm.account_code);
            formData.append("currency",this.previewForm.currency);
            formData.append("account_no",this.previewForm.account_no);
            formData.append("bank_name",this.previewForm.bank_name);
            formData.append("description",this.previewForm.description);

            this.accountIsSaving = true;
            try{
                let res = await this.$axios.post('/account/add',formData);
                if(res.data.status_code == "1053"){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    this.$store.commit('accounts_module/add_accounts_list',res.data.account);
                    
                    this.accountSaved = true;

                    this.resetPreview();

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.includes('already exists')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error,
                        });

                    }else if(error.response.data.error.includes("doesn't support")){

                        let subscriptionPath = window.location.origin + "/subscription";

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error 
                            + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.accountIsSaving = false;

            }

        },
        async updateAccountHandle(){

            let formData = new FormData();

            formData.append("id",this.previewForm.id);
            formData.append("account_name",this.previewForm.account_name.trim());
            formData.append("account_code",this.previewForm.account_code);
            formData.append("currency",this.previewForm.currency);
            formData.append("account_no",this.previewForm.account_no);
            formData.append("bank_name",this.previewForm.bank_name);
            formData.append("description",this.previewForm.description);

            this.accountIsSaving = true;
            try{
                let res = await this.$axios.post('account/update',formData);
                if(res.data.status_code == "1054"){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    let accountIndex = this.accountsList.findIndex((account) => account.id == res.data.account.id)

                    this.accountsList[accountIndex] = res.data.account

                    this.$store.commit('accounts_module/update_accounts_list',res.data.account);

                    this.$store.commit('expenses_module/CLEAR_MODULE');

                    if(this.previewForm.id == res.data.account.id){

                        this.accountSaved = true;

                        this.closePreview();

                    }

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.id){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.id[0],
                        });

                    }else if(error.response.data.error.includes('already exists')){
                        
                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error,
                        });

                    }else if(error.response.data.error.includes("doesn't support")){

                        let subscriptionPath = window.location.origin + "/subscription";

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error 
                            + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.accountIsSaving = false;

            }

        },
        deleteAccountHandle(listedAccount,index){

            if(!this.tourIsActive){
            
                MessageBox.confirm(
                    'Are you sure you want to delete the account?',
                    'Warning',
                    {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel',
                        type: 'warning',
                    }
                ).then(async ()=>{

                    this.deletingItems.push(listedAccount.id);
                    this.deleteLoader = true;
                    
                    let loader = Loading.service({
                        text: "The account is being removed. Please wait!",
                        fullscreen: true,
                    });

                    try{
                        let res = await this.$axios.delete(`account/remove/${listedAccount.id}`);
                        if(res.data.status_code == "1055"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: res.data.message,
                            });

                            this.$store.commit('expenses_module/CLEAR_MODULE');

                            // this.$store.commit('accounts_module/delete_accounts_list',index);

                            //removing deleted item from checked items list
                            this.checkedItems = this.checkedItems.filter((item) => {

                                if(item != listedAccount.id){
                                    
                                    return item;
                                    
                                }

                            });

                            this.accountsLoading = true;

                            this.$store.dispatch('accounts_module/fetchStoreAccountsRequest',{size:11, page:1, loadData: 'new'});

                        }
                    }catch(error){

                        if(error.response){

                            if(error.response.data.error.account_id){

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.error.account_id[0],
                                });

                            }else if(error.response.data.error.includes("doesn't support")){

                                let subscriptionPath = window.location.origin + "/subscription";

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    dangerouslyUseHTMLString: true,
                                    message: error.response.data.error 
                                    + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                });

                            }else{

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }finally{

                        loader.close();

                        this.deletingItems = [];
                        this.deleteLoader = false;

                    }

                }).catch(() => {})
            
            }

        },
        //add/update & delete account end

        //bulk action start
        accountBulkActionHandle(action){

            this.resetPreview();

            MessageBox.confirm(
                'Do you really want to make the changes?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async ()=>{

                if(action == 'delete'){

                    let formData = new FormData();

                    formData.append('resource_type', 'account');
                    formData.append("resource_ids", JSON.stringify(this.checkedItems));
                    formData.append("action", action);

                    this.deletingItems = _.cloneDeep(this.checkedItems);
                    this.deleteLoader = true;

                    let loader = Loading.service({
                        text: "The selected accounts are being removed. Please wait!",
                        fullscreen: true,
                    });

                    try{
                        let res = await this.$axios.post('/bulk-action',formData);
                        if(res.data.status_code == "2031"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: 'Accounts have been successfully removed.',
                            });

                            // let payload = {
                            //     action: 'delete',
                            //     listIds: this.checkedItems
                            // }

                            // this.$store.commit('accounts_module/accounts_bulk_action',payload);

                            this.accountsLoading = true;

                            this.$store.dispatch('accounts_module/fetchStoreAccountsRequest',{size:11, page:1, loadData: 'new'});

                            this.checkedItems = [];
                            this.deletingItems = [];
                            this.deleteLoader = false;

                        }
                    }catch(error){

                        this.deletingItems = [];
                        this.deleteLoader = false;

                        if(error.response.data.error.includes("doesn't support")){

                            let subscriptionPath = window.location.origin + "/subscription";

                            this.$message({
                                type: 'error',
                                showClose: true,
                                dangerouslyUseHTMLString: true,
                                message: error.response.data.error 
                                + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                            });

                        }else if(error.response.data.message){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: "One of the accounts may have been deleted or isn't available.",
                            });

                        }

                    }finally{

                        loader.close();

                    }

                }

            }).catch(() => {})

        },
        //bulk action end

        //search blog category start
        searchAccount(){

            this.resetPreview();

            this.accountsLoading = true;

            clearTimeout(this.timer)

            this.timer = setTimeout(() => {

                if(this.searchedItem.length > 0){

                    let payload = {
                        keyword: this.searchedItem,
                    }

                    this.$store.commit('accounts_module/update_searched_keyword',payload)
                    this.$store.dispatch('accounts_module/fetchStoreAccountsRequest',{size:11, page:1, loadData: 'new'});

                }else{

                    this.$store.commit('accounts_module/remove_searched_keyword')
                    this.$store.dispatch('accounts_module/fetchStoreAccountsRequest',{size:11, page:1, loadData: 'new'});

                }

            }, 500)

        },
        clearSearch(){

            this.searchedItem = ''

            this.searchAccount();

        },
        //search blog category end

        //other methods start
        isDeletingItem(id){

            let find = this.deletingItems.find(x=>x == id);

            if(find){

                return true;

            }else{

                return false;

            }

        },
        checkedItemHandle(accountId){

            if(!this.tourIsActive && accountId != -1 && !this.previewForm.id){

                let index = this.checkedItems.findIndex(item => item == accountId);

                if(index != -1){

                    this.checkedItems.splice(index,1)

                }else{

                    this.checkedItems.push(accountId)

                }

            }

        },
        toggleIsCheckedAll(){

            if(!this.tourIsActive && !this.previewForm.id){

                if((this.checkedItems.length == this.accountsList.length) ||
                (this.checkedItems.length == this.accountsList.length)){

                    this.checkedItems = []

                }else if(this.checkedItems.length < this.accountsList.length){

                    this.checkedItems = []
                    
                    this.accountsList.forEach(account => {

                        if(account.id != -1){

                            this.checkedItems.push(account.id)

                        }

                    });

                }

            }

        },
        clearSelection(){

            this.checkedItems = [];

        },
        expand(){

            this.$refs.viewInputForm.classList.toggle('fullWidthSlide');

        },
        accountsKeyEvent(e){

            if(e.key == 'Escape'){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.$refs.viewInputForm.classList.remove('fullWidthSlide');

                }else if(this.$refs.viewInputForm.classList.contains('activeRow')){

                    this.closePreview();

                }

            }

        },
        //other methods end

        //tour methods start
        startAccountsTour(){

            this.$tours.accountsTour.start();

            this.tourIsActive = true;

            if(this.previewForm.id != ''){

                this.closePreview();

            }

        },
        handleNextStep(currentStep){

            if(currentStep + 1 == 7){

                document.querySelector(".tableActionBtns").style.position = "static"

                document.querySelector(".productSlideBox").style.overflowY  = "hidden"

                this.createAccount();

            }

            if(currentStep >= 14){

                document.querySelector(".tableActionBtns").style.position = "sticky"

            }

            if(currentStep + 1 == 19){

                if(!this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

            if(currentStep + 1 == 20){

                this.expand();

            }

        },
        handlePreviousStep(currentStep){

            if(currentStep == 7){

                this.resetPreview();

                document.querySelector(".tableActionBtns").style.position = "sticky"

                document.querySelector(".productSlideBox").style.overflowY  = "auto"

            }

            if(currentStep <= 15){

                document.querySelector(".tableActionBtns").style.position = "static"

            }

            if(currentStep == 19){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

            if(currentStep == 20){

                if(!this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

        },
        handleTourStop(){

            document.querySelector(".tableActionBtns").style.position = "sticky"

            document.querySelector(".productSlideBox").style.overflowY  = "auto"

            this.resetPreview();

            this.tourIsActive = false;

        },
        handleTourFinish(){
        
            document.querySelector(".tableActionBtns").style.position = "sticky"

            document.querySelector(".productSlideBox").style.overflowY  = "auto"

            this.resetPreview();

            this.tourIsActive = false;
        
        },
        handleTourSkip(){

            document.querySelector(".tableActionBtns").style.position = "sticky"

            document.querySelector(".productSlideBox").style.overflowY  = "auto"

            this.resetPreview();

            this.tourIsActive = false;

        }
        //tour methods end

    },
    mounted(){

        this.$refs.accountsTable.addEventListener('scroll', this.handleScrollEvent);

        window.addEventListener('keydown',this.accountsKeyEvent);

        window.addEventListener('beforeunload', (event) => {

            let index = null;

            this.accountsList.forEach((account) => {

                if(account.id == -1){

                    index = this.accountsList.indexOf(account);

                }

            });

            if(index != null){

                event.returnValue = "Are you sure you want to leave? entered information will be lost";

            }

        });

        //master search
        if(this.$route.query.search){
      
            this.searchedItem = this.$route.query.search;

            if(this.$route.query.preview == 1){

                this.isMasterSearched = true;

            }

            this.$router.replace({'query': null});

            this.searchAccount();

        }

    },
    async beforeMount(){

        this.accountsLoading = true;
        try{

            if((!this.storeAccounts || this.storeAccounts.length == 0) && this.accountsSearchKeyword.length == 0){

                this.$store.dispatch('accounts_module/fetchStoreAccountsRequest',{size:11, page:1, loadData: 'new'});

            }else{

                this.accountsList = _.cloneDeep(this.storeAccounts);
                this.accountsLoading = false;

            }

            if(!this.storeAllCountries || this.storeAllCountries.length == 0){

                this.$store.dispatch('countries_module/fetchAllCountries');

            }else{

                let tempCurrencies = [];

                this.storeAllCountries.forEach( country => {

                    tempCurrencies.push(country.currency_unit);

                })

                this.currencyList = _.cloneDeep(tempCurrencies);

            }

            if(this.accountsSearchKeyword.length > 0){

                this.searchedItem = this.accountsSearchKeyword;

            }

        }catch(error){

            this.accountsLoading = false;

            this.$message({
                type: 'error',
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
            });

        }

    },
    beforeDestroy(){

        window.removeEventListener('keydown',this.accountsKeyEvent);

    },
    beforeRouteLeave(to, from, next){

        let index = null;

        this.accountsList.forEach((account) => {

            if(account.id == -1){

                index = this.accountsList.indexOf(account);

            }

        });

        if(window.innerWidth <= 1024){

            document.body.classList.remove('activeIpad');
            document.body.classList.add('hideSideBar');

        }

        if((this.previewForm.id || index != null) && to.fullPath != '/login'){

            MessageBox.confirm(
                'You may have unsaved changes, Proceeding will discard them',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Process',
                    cancelButtonText: 'Stay',
                }
            ).then(()=>{

                this.resetPreview();

                next();

            }).catch(()=>{})

        }else{

            next();

        }

    },

}
</script>

<style>

</style>